/* eslint-disable */
import $ from 'jquery';
import Swal from 'sweetalert2';
class Loader {
    /*
    * Simcyfy show loader
    */
    show(text, options) {
        /**
         * remove existing loaders
         */
        this.hide();
        if (options === undefined) {
            options = {
                color: "#007bff",
                background: "rgba(298, 294, 290, 0.9)"
            };
        } else {
            if (options.background === undefined) {
                options.background = "rgba(298, 294, 290, 0.9)";
            }
            if (options.color === undefined) {
                options.color = "#007bff";
            }
        }
        if(text) {
            $("body").append('<h3 class="loading-text">'+text+'</h3>');
        }
        $("body").append('<div class="loading-overlay" style="background-color:' + options.background + ';z-index: 999;"><div class="loader-box"><div class="circle-loader"></div></div></div>');
        $("body").append('<style class="notify-styling">.circle-loader:before { border-top-color: ' + options.color + '; }</style>');
    }

    showModal(text) {
      this.hide();
      $("body").append('<div class="modal-loader" style="position: fixed; z-index: 1000; width: 100%; height: 100%;padding-top: 60px;"></div>');
      $('.modal-loader').html('<div class="modal-dialog" style="z-index: 3000; display:block;"><div class="modal-content"><div class="modal-header" style="display: block;">'
      +'<div class="lds-dual-ring to-left" style="margin-right: 20px;margin-top: 2px;"></div>'
      +'<h5 class="modal-title">'+text+'</h5></div></div></div>'
      +'<div class="loading-overlay" style="background-color:rgba(298, 294, 290, 0.9);z-index: 999;"><div class="loader-box"><div class="circle-loader"></div></div></div>');
    }
    /*
    * Simcyfy hide loader
    */
    hide() {
        $(".loading-overlay, .notify-styling").remove();
        $(".loading-overlay-inside").remove();
        $(".loading-text").remove();
        $(".modal-loader").remove();
    }

    showLoadingToast(title){
        Swal.fire({
            html: '<div style="z-index:2000000"><div class="lds-dual-ring to-left"></div><div class="to-left" style="margin-top: 5px; margin-left: 5px;">'+title+'</div></div>',
            showConfirmButton: false,
            iconHtml: '<div class="lds-dual-ring"></div>',
            allowOutsideClick: false,
            closeOnClickOutside: false,
            closeOnConfirm: false,
            closeOnCancel: false,
            closeClick: false,
            customClass: {
              container: 'position-absolute'
            },
            toast: true,
            position: 'top-right'
        })
    }

    hideDialog(){
      Swal.close();
    }

    showLoaderInside(element, options) {
        /**
         * remove existing loaders
         */
        this.hide();
        if (options === undefined) {
            options = {
                color: "#007bff",
                background: "rgba(298, 294, 290, 0.9)"
            };
        } else {
            if (options.background === undefined) {
                options.background = "rgba(298, 294, 290, 0.9)";
            }
            if (options.color === undefined) {
                options.color = "#007bff";
            }
        }
        $(element).append('<div class="loading-overlay-inside" style="background-color:' + options.background + ';position: absolute;"><div class="loader-box"><div class="circle-loader"></div></div></div>');
        $(element).append('<style class="notify-styling">.circle-loader:before { border-top-color: ' + options.color + '; }</style>');
    }

    showLoaderInsideTime(element, options) {
        setTimeout(() => {
            /**
             * remove existing loaders
             */
            this.hide();
            if (options === undefined) {
                options = {
                    color: "#007bff",
                    background: "rgba(298, 294, 290, 0.9)"
                };
            } else {
                if (options.background === undefined) {
                    options.background = "rgba(298, 294, 290, 0.9)";
                }
                if (options.color === undefined) {
                    options.color = "#007bff";
                }
            }
            $(element).append('<div class="loading-overlay-inside" style="background-color:' + options.background + ';position: absolute;"><div class="loader-box"><div class="circle-loader"></div></div></div>');
            $(element).append('<style class="notify-styling">.circle-loader:before { border-top-color: ' + options.color + '; }</style>');
        }, 50);
    }
}

export default new Loader();

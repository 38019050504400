<template>
  <div>
    <Page :locked="false" :title="'Assine o plano de email'" :message="'Mantenha a sua lista de clientes engajados com a nossa plataforma robusta de envio de email marketing e de conteúdo.'" :features="features" :featureTitle="'O que tem no Email?'" @unsubscribeIt="subscribeMail">
      <div class="content">
        <PageHeader :breadcrumbLinks="breadcrumbLinks" :title="'E-mail'" :subtitle="$t('generic-str.send')" />
        <div class="page-content container-fluid">
          <div class="row">
            <div class="col-lg-7">
              <div class="card card-tabs">
                <div class="card-header">
                  <div class="card-header-left">
                    <div class="flex-row">
                      <div>
                        <div class="card-header-photo"></div>
                      </div>
                      <div style="margin-left: 10px;" class="c-point" v-b-toggle.sidebar-right-domain>
                        <div class="c-pointer info-whatsapp-button">
                          <span class="card-header-name">Rementente</span>
                          <span class="card-header-number">{{ form.domain }}</span>
                          <span class="material-symbols-outlined card-header-sync">sync</span>
                        </div>
                        <div class="card-header-number-text">
                          Domínio do remetente
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-header-right" v-b-toggle.sidebar-right-template>
                    <div>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_2330_41368)">
                          <path
                            d="M7.15039 21H11.1504C11.1504 22.1 10.2504 23 9.15039 23C8.05039 23 7.15039 22.1 7.15039 21ZM5.15039 20H13.1504V18H5.15039V20ZM16.6504 10.5C16.6504 14.32 13.9904 16.36 12.8804 17H5.42039C4.31039 16.36 1.65039 14.32 1.65039 10.5C1.65039 6.36 5.01039 3 9.15039 3C13.2904 3 16.6504 6.36 16.6504 10.5ZM14.6504 10.5C14.6504 7.47 12.1804 5 9.15039 5C6.12039 5 3.65039 7.47 3.65039 10.5C3.65039 12.97 5.14039 14.39 6.00039 15H12.3004C13.1604 14.39 14.6504 12.97 14.6504 10.5ZM21.5204 8.37L20.1504 9L21.5204 9.63L22.1504 11L22.7804 9.63L24.1504 9L22.7804 8.37L22.1504 7L21.5204 8.37ZM19.1504 7L20.0904 4.94L22.1504 4L20.0904 3.06L19.1504 1L18.2104 3.06L16.1504 4L18.2104 4.94L19.1504 7Z"
                            id="light-svg" fill="#16CD08" />
                        </g>
                        <defs>
                          <clipPath id="clip0_2330_41368">
                            <rect width="24" height="24" fill="white" transform="translate(0.900391)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <span>Selecionar template</span>
                  </div>
                </div>
                <div class="card-body">
                  <div class="form-body">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="tab-content">
                          <div class="tab-pane active" id="simple">
                            <div class="form-group row">
                              <div class="col-lg-1 col-sm-1 col-md-1 col-2">
                                <span class="material-symbols-outlined info-icons">campaign</span>
                              </div>
                              <div class="col-lg-7 col-sm-7 col-md-7 col-10">
                                <input type="text" class="form-control form-control-send" placeholder="Nome da campanha"
                                  v-model="form.name">
                              </div>
                            </div>
                            <div class="form-group row">
                              <div class="col-lg-1 col-sm-1 col-md-1 col-2">
                                <span class="material-symbols-outlined info-icons">business</span>
                              </div>
                              <div class="col-lg-7 col-sm-7 col-md-7 col-10">
                                <div class="input-group domain">
                                  <input type="text" class="form-control form-control-send reply-input" placeholder="Remetente"
                                  v-model="form.from_t">
                                  <div class="input-group-prepend">
                                    <span
                                      class="input-group-text input-domain-name"
                                      >@ {{form.domain}}</span
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form-group row">
                              <div class="col-lg-1 col-sm-1 col-md-1 col-2">
                                <span class="material-symbols-outlined info-icons">person_outline</span>
                              </div>
                              <div class="col-lg-7 col-sm-7 col-md-7 col-10">
                                <input type="text" class="form-control form-control-send" placeholder="Nome do remetente"
                                  v-model="form.from_name">
                              </div>
                            </div>
                            <div class="form-group row">
                              <div class="col-lg-1 col-sm-1 col-md-1 col-2">
                                <span class="material-symbols-outlined info-icons">short_text</span>
                              </div>
                              <div class="col-lg-7 col-sm-7 col-md-7 col-10">
                                <input type="text" class="form-control form-control-send" placeholder="Assunto"
                                  v-model="form.subject">
                                <Emojis
                                  v-on:select="putEmoji"
                                  title=""
                                  class="emoji-subject"
                                />
                                <ul
                                  class="
                                    actions
                                    text-right
                                    actions-var
                                    subjectvar
                                  "
                                  style="position: absolute !important"
                                >
                                  <li class="dropdown">
                                    <a
                                      role="button"
                                      class="emoji-color btn-add-var-subject"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="true"
                                    >
                                      <i
                                        class="
                                          far
                                          fa-bookmark
                                          actions-icon
                                          emoji-color
                                        "
                                      ></i>
                                    </a>
                                    <div
                                      class="
                                        dropdown-menu
                                        var-add-action-subject
                                        dropdown-menu-right
                                        animation
                                      "
                                      v-bind:class="{ show: addVarsSubject }"
                                      x-placement="bottom-end"
                                      style="
                                        position: absolute;
                                        transform: translate3d(
                                          -232px,
                                          20px,
                                          0px
                                        );
                                        top: 0px;
                                        left: 0px;
                                        will-change: transform;
                                      "
                                    >
                                      <a
                                        v-for="varSMS in vars"
                                        v-bind:key="varSMS.value"
                                        class="
                                          dropdown-item
                                          var-add-action-item
                                        "
                                        @click="addVar(varSMS.value, 'subject')"
                                        >{{ varSMS.name }}</a
                                      >
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div class="form-group row">
                              <div class="col-lg-1 col-sm-1 col-md-1 col-2">
                                <span class="material-symbols-outlined info-icons">email</span>
                              </div>
                              <div class="col-lg-7 col-sm-7 col-md-7 col-10">
                                <input type="text" class="form-control form-control-send" placeholder="Email de resposta"
                                  v-model="form.reply_email">
                              </div>
                            </div>
                            <div class="form-group row">
                              <div class="col-lg-1 col-sm-1 col-md-1 col-2">
                                <span class="material-symbols-outlined info-icons">supervisor_account</span>
                              </div>
                              <div class="col-lg-10 col-sm-10 col-md-10 col-9">
                                <div class="row">
                                  <div class="col-sm-8 w68 relative tags-container" style="padding-right: 5px;"
                                    v-if="selectedGroups.length == 0 && selectedContacts.length == 0">
                                    <vue-tags-input v-model="form.to_t" :class="'form-control form-control-send'"
                                      :tags="form.tags" @before-deleting-tag="onDeleteTag" @tags-changed="(newTags) => (form.tags = newTags)"
                                      :placeholder="'Destinatário: Ex.: marcos@gmail.com'" :separators="[',', ';', ' ']" :add-on-key="[13, ',', ';', ' ']" />
                                  </div>
                                  <div class="col-sm-3 no-margin no-padding icon-sends">
                                    <span class="material-symbols-outlined span-close c-pointer"
                                      v-b-toggle.sidebar-right-contact v-tooltip.top="'Enviar por Contatos'">contact_page</span>
                                    <span class="material-symbols-outlined span-close c-pointer"
                                      v-b-toggle.sidebar-right-group v-tooltip.top="'Enviar por Grupos'">group</span>
                                    <span class="material-symbols-outlined span-close c-pointer"
                                      v-b-toggle.sidebar-right-import v-tooltip.top="'Enviar por Arquivo'">file_upload</span>
                                    <span class="material-symbols-outlined span-close c-pointer add-circle hide"
                                      @click="addInput()">add_circle</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form-group row">
                              <div class="col-lg-1 col-sm-1 col-md-1 col-2">
                                <span class="material-symbols-outlined info-icons">email</span>
                              </div>
                              <div class="col-lg-7 col-sm-7 col-md-7 col-10">
                                <div class="form-group mb-4">
                                  <label>Adicionar anexo: </label>
                                  <span class="material-icons-outlined c-pointer" v-if="form.attachment" @click="removeAttachment" style="position: absolute;right: 10px;top: 0px;">close</span>
                                  <div class="content-upload relative" ref="dropz">
                                    <div class="content-inside">
                                      <span class="material-symbols-outlined">upload_file</span>
                                      <h4>Clique aqui ou arraste e solte</h4>
                                    </div>
                                    <input
                                      type="file"
                                      ref="fileInput"
                                      multiple
                                      @change="onFilePicked"/>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form-group row">
                              <div class="divider-form"></div>
                            </div>
                            <div class="form-group row">
                              <div class="col-sm-12">
                                <email-input
                                  v-model="form.body"
                                  :normalize="form.normalize"
                                  :variables="vars"
                                />
                              </div>
                            </div>
                            <div class="form-group row text-right">
                              <div class="col-sm-12">
                                <div>
                                  <div class="right-items">
                                    <button
                                      class="send-button send-button-outline"
                                      v-modal="{ target: 'preview-modal', data: form.body }"
                                      @click.prevent="mountPreview"
                                    >
                                      Preview
                                    </button>
                                    <button
                                      class="send-button send-button-outline"
                                      style="margin-left: 15px"
                                      :modal="{ target: 'send-test-modal', data: form }"
                                      @click="mountTest"
                                    >
                                      {{ $t('generic-str.send-test') }}
                                    </button>
                                    <div class="calendar_form">
                                      <date-picker v-model="form.datetime" :config="options" v-b-tooltip.hover
                                        :title="form.datetime" class="form-control input-date"
                                        :placeholder="$t('generic-str.date-hour')"></date-picker>
                                    </div>
                                    <button type="button" class="send-button send-button-outline calendar_icon"
                                      @click="changeSchedule()">Agendar envio</button>
                                    <button class="btn send-button" type="button" :class="{
                                      'qt-loader qt-loader-mini qt-loader-right': isSending
                                    }" :disabled="isSending || importing" id="msgSend" @click="send">Enviar</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg preview-div">
              <div class="preview-wrapper">
                <div class="card card-preview" :class="[this.form.body === this.defaultMailBody || this.form.body === '<html>\n<head>\n\t<title></title>\n</head>\n<body></body>\n</html>\n' ? 'default-message' : '']">
                  <div class="card-body">
                    <p class="card-text"
                      v-if="this.form.body === '<html>\n<head>\n\t<title></title>\n</head>\n<body></body>\n</html>\n' || this.form.body === this.defaultMailBody || this.form.body === 0">
                      Olá cliente 🤩 Voce ganhou um Cupom de desconto valido ate o
                      dia xxx.
                    </p>
                    <!-- <p v-else class="card-text" v-html="form.body">
                    </p> -->
                    <div class="preview-iframe" v-else>
                      <iframe :srcdoc="form.body"></iframe>
                    </div>
                  </div>
                </div>
                <img
                  class="phone-img"
                  src="@/assets/phone.svg"
                  alt="Phone Background"
                />
              </div>
            </div>
          </div>
        </div>
        <create-contact-modal
          id="create-contact"
          ref="create-contact"
        ></create-contact-modal>
        <create-group-modal
          id="create-group"
          ref="create-group"
        ></create-group-modal>
        <group-send-modal
          id="group-send-modal"
          ref="group-send-modal"
        ></group-send-modal>
        <contact-send-modal
          id="contact-send-modal"
          ref="contact-send-modal"
        ></contact-send-modal>
        <b-sidebar class="b-sidebar-modal" id="sidebar-right-template" :title="'Templates'" no-header right backdrop shadow>
          <div class="col sidebar-header">
            <p>Templates</p>
            <span v-b-toggle.sidebar-right-template class="material-symbols-outlined sidebar-close">close</span>
          </div>
          <div class="col desc-column">
            <p>
              Simplifique suas campanhas com templates exclusivos. Alcance o público certo, no momento certo, com facilidade e
              eficiência, garantindo o sucesso de suas campanhas de marketing.
            </p>
          </div>
          <form class="add-prod-form col mb-0">
            <!-- Name -->
            <div class="input-group">
              <div class="search-input relative">
                <input placeholder="Buscar" class="form-control" ref="searchTemplatesInput" />
                <span class="material-symbols-outlined c-pointer" @click="searchTemplates">search</span>
              </div>
            </div>
            <div class="input-group">
              <div class="header-options">
                <div class="header-options-title" style="width: 50%;">
                </div>
                <div class="header-options-buttons header-options-buttons-template hide">
                  <h4 style="color: #697077;">Ordenar por (padrão) </h4>
                  <svg width="24" height="24" class="c-pointer" viewBox="0 0 24 24" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M17 20L21 16M3 4H16H3ZM3 8H12H3ZM3 12H12H3ZM17 8V20V8ZM17 20L13 16L17 20Z" stroke="#697077"
                      stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
            </div>
            <div class="text-center py-5 w100" v-if="fetchedTemplates && templates.length == 0">
              <span class="material-symbols-outlined no-template">palette</span>
              Nenhum template encontrado
            </div>
            <div class="text-center py-5 w100" v-if="!fetchedTemplates">
              <img src="assets/img/loading.gif">
            </div>
            <div class="input-group" v-else v-for="(templ, i) in templates" :key="templ.id">
              <div class="row row-list row-template-list">
                <div class="col-sm-1">
                  <input v-model="form.template" type="radio" :value="templ" v-b-toggle.sidebar-right-template
                    @change="changeTemplate(templ)" :id="'contact-template-check-' + i" />
                </div>
                <div class="col-sm-9">
                  <div class="flex">
                    <span>{{ templ.name }}</span>
                  </div>
                </div>
                <div class="col-sm-2">
                  <span class="material-symbols-outlined icon-show-hide c-pointer" @click="showTemplate(templ)"
                    v-if="!templ.show">visibility_off</span>
                  <span class="material-symbols-outlined icon-show-hide c-pointer" @click="hideTemplate(templ)"
                    v-if="templ.show">visibility</span>
                </div>
              </div>
              <div class="row row-templates" v-if="templ.show" style="margin-bottom: 10px;">
                <div class="col-sm-12">
                  <div class="card-template">
                    <div class="card relative">
                      <div class="card-body">
                        <img :src="`${templ.preview_url}`" alt="">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </b-sidebar>
        <b-sidebar class="b-sidebar-modal" id="sidebar-right-group" :title="'Grupos'" no-header right backdrop shadow>
          <div class="col sidebar-header">
            <p>Grupos</p>
            <span v-b-toggle.sidebar-right-group class="material-symbols-outlined sidebar-close">close</span>
          </div>
          <div class="col desc-column">
            <p>
              Simplifique suas campanhas com nossa ferramenta de seleção de grupos de contatos. Alcance o público certo, no
              momento certo, com facilidade e eficiência, garantindo o sucesso de suas campanhas de marketing.
            </p>
          </div>
          <form class="add-prod-form col mb-0">
            <!-- Name -->
            <div class="input-group">
              <div class="search-input relative">
                <input placeholder="Buscar" class="form-control" ref="searchInput" />
                <span class="material-symbols-outlined c-pointer" @click="searchGroup">search</span>
              </div>
            </div>
            <router-link to="/contacts/groups">
              <div class="input-group">
                <div class="header-options">
                  <div class="header-options-title" style="width: 55%;">
                    <span>Meus grupos</span>
                  </div>
                  <div class="header-options-buttons c-pointer">
                    <span class="material-symbols-outlined">settings</span>
                    <h4>Gerenciar grupos</h4>
                  </div>
                </div>
              </div>
            </router-link>
            <div class="input-group">
              <div class="text-center py-5 w100" v-if="!fetchedGroups">
                <img src="assets/img/loading.gif">
              </div>
              <div v-if="fetchedGroups" class="w100">
                <div class="row row-list" v-for="(group, i) in groups" :key="group.id">
                  <div class="col-sm-1">
                    <div class="custom-control custom-checkbox">
                      <input v-model="groupsToAdd" type="checkbox" class="custom-control-input" :value="group"
                        :id="'group-check-' + i" />
                      <label class="custom-control-label" :for="'group-check-' + i"></label>
                    </div>
                  </div>
                  <div class="col-sm-10">
                    <div class="flex">
                      <span class="material-symbols-outlined">group</span>
                      <span>{{ group.name }}</span>
                    </div>
                  </div>
                  <div class="col-sm-1">

                  </div>
                </div>
                <div class="row">
                  <pagination v-model="currentPageGroup" :total-pages="pagesGroup" @change="getGroups" />
                </div>
              </div>
            </div>
            <div class="input-group text-right flex-c modal-footer">
              <button type="button" v-b-toggle.sidebar-right-group class="btn btn-yup btn-add align-r"
                @click="applyGroups()">Aplicar</button>
            </div>
          </form>
        </b-sidebar>
        <b-sidebar class="b-sidebar-modal" id="sidebar-right-contact" :title="'Contatos'" no-header right backdrop shadow>
          <div class="col sidebar-header">
            <p>Contatos</p>
            <span v-b-toggle.sidebar-right-contact class="material-symbols-outlined sidebar-close">close</span>
          </div>
          <div class="col desc-column">
            <p>
              Simplifique suas campanhas com nossa ferramenta de seleção de contatos. Alcance o público certo, no momento
              certo, com facilidade e eficiência, garantindo o sucesso de suas campanhas de marketing.
            </p>
          </div>
          <form class="add-prod-form col mb-0">
            <!-- Name -->
            <div class="input-group">
              <div class="search-input relative">
                <input placeholder="Buscar" class="form-control" v-model="formContact.q" />
                <span class="material-symbols-outlined c-pointer" @click="searchContacts"
                  ref="searchInputContact">search</span>
              </div>
            </div>
            <div class="input-group">
              <div class="text-center py-5 w100" v-if="!fetchedContacts">
                <img src="assets/img/loading.gif">
              </div>
              <div v-if="fetchedContacts" class="w100">
                <div class="row row-list" v-for="(contact, i) in contacts" :key="contact.id">
                  <div class="col-sm-1">
                    <div class="custom-control custom-checkbox" style="margin-top: -9px;">
                      <input v-model="contactsToAdd" type="checkbox" class="custom-control-input" :value="contact"
                        :id="'contact-check-' + i" />
                      <label class="custom-control-label" :for="'contact-check-' + i"></label>
                    </div>
                  </div>
                  <div class="col-sm-11" style="padding-left: 0px;">
                    <div>
                      <div class="flex">
                        <div class="profile-photo">
                          <figure :style="{ backgroundColor: $strToColor(contact.first_name + '' + contact.last_name) }">
                            <div class="fallback-image">
                              {{ initials(contact.first_name, contact.last_name) }}
                            </div>
                          </figure>
                        </div>
                        <span class="contact-name">
                          <span>{{ contact.first_name }}</span>
                          <span class="contact-name contact-phone">
                            <template v-if="contact.email">
                              {{ contact.email }}
                            </template>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <pagination v-model="currentPage" :total-pages="pages" @change="getContacts" />
                </div>
              </div>
            </div>
            <div class="input-group text-right flex-c modal-footer">
              <button type="button" v-b-toggle.sidebar-right-contact class="btn btn-yup btn-add align-r"
                @click="applyContacts()">Aplicar</button>
            </div>
          </form>
        </b-sidebar>
        <b-sidebar class="b-sidebar-modal" id="sidebar-right-import" :title="'Importar'" no-header right backdrop shadow>
          <div class="col sidebar-header">
            <p>Importar contatos</p>
            <span v-b-toggle.sidebar-right-import class="material-symbols-outlined sidebar-close c-pointer">close</span>
          </div>
          <div class="col desc-column">
            <p>
              Faça upload de arquivos csv para importar contatos
            </p>
          </div>
          <form class="add-prod-form col mb-0">
            <!-- Name -->
            <div class="input-group">
              <div class="drop-item-upload">
                <img src="@/assets/csv_import.svg" v-if="!importing" />
                <div id="cont" data-pct="100" :class="{ 'hide': !importing }">
                  <svg id="svg" width="200" height="200" viewPort="0 0 100 100" version="1.1"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle r="90" cx="100" cy="100" fill="transparent" stroke-dasharray="565.48" stroke-dashoffset="0">
                    </circle>
                    <circle id="bar" r="90" cx="100" cy="100" fill="transparent" stroke-dasharray="565.48"
                      stroke-dashoffset="0"></circle>
                  </svg>
                </div>
                <h3>Clique ou arraste seu cvs aqui</h3>
                <button type="button" @click="openFileUpload">Selecionar Arquivo do seu dispositivo</button>
                <input ref="uploadedFile" type="file" accept=".csv" hidden @change="uploadFile" />
              </div>
              <div class="row csv-file" v-if="form.file">
                <div class="icon-div">
                  <img src="assets/img/csv_icon.svg">
                </div>
                <div class="name-csv-div">
                  <span>{{ form.file }}</span>
                  <div class="csv-progress"></div>
                </div>
                <div class="name-csv-percent">100%</div>
              </div>
              <p class="drop-item-upload-p">
                Alguns formatos de dados, como nomes e números podem não ser reconhecidos. ler mais
              </p>
              <div>
                <a
                  class="sample-area d-flex align-items-center"
                  href="/exemplos/example_mail.csv"
                  download="example.csv"
                  v-tooltip.top="$t('sms.send-msg.tabs.archives.lbl-tooltip-archive')">
                    <!-- {{$t('generic-str.example')}} -->
                    <span class="material-icons-outlined" style="margin-right: 5px;">file_download</span>&nbsp;&nbsp;Baixar arquivo de exemplo
                </a>
              </div>
            </div>
          </form>
        </b-sidebar>
        <b-sidebar class="b-sidebar-modal" id="sidebar-right-domain" :title="'Domínios'" no-header right backdrop shadow>
          <div class="col sidebar-header">
            <p>Domínios</p>
            <span v-b-toggle.sidebar-right-numbers class="material-symbols-outlined sidebar-close">close</span>
          </div>
          <div class="col desc-column">
            <p>
              Escolha um domínio de remetente:
            </p>
          </div>
          <form class="add-prod-form col mb-0">
            <!-- Name -->
            <div class="input-group hide">
              <div class="search-input relative">
                <input placeholder="Buscar" class="form-control" />
                <span class="material-symbols-outlined c-pointer" @click="searchContacts"
                  ref="searchInputContact">search</span>
              </div>
            </div>
            <div class="input-group">
              <div class="w100">
                <div class="row row-list" v-for="(domain, i) in domains" :key="domain.id">
                  <div class="col-sm-1">
                    <div>
                      <input v-model="form.domain" type="radio" :value="domain.domain" :id="'domain-check-' + i" />
                    </div>
                  </div>
                  <div class="col-sm-7" style="padding-left: 0px;">
                    <div class="flex">
                      <span class="contact-name">
                        {{ domain.domain }}
                      </span>
                    </div>
                  </div>
                  <div class="col-sm-4 text-right hide">
                    <span class="contact-name contact-phone">
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-group text-right flex-c modal-footer">
              <button type="button" v-b-toggle.sidebar-right-domain class="btn btn-yup btn-add align-r">Aplicar</button>
            </div>
          </form>
        </b-sidebar>
      </div>
    </Page>
    <preview-modal id="preview-modal" ref="preview-modal"></preview-modal>
    <send-test-modal
      id="send-test-modal"
      ref="send-test-modal"
    ></send-test-modal>
  </div>
</template>

<script>
import 'bootstrap-vue/dist/bootstrap-vue.css';
import axios from '@/plugins/axios';
import MailService from '@/services/mail.service';
import Tab from '@/directives/Tab';
import PageHeader from '@/components/rebranding/PageHeader.vue';
import CreateContactModal from '@/components/contacts/CreateContactModal.vue';
import PreviewModal from '@/components/rebranding/mails/PreviewModal.vue';
import GroupSendModal from '@/components/groups/GroupSendModal.vue';
import ContactSendModal from '@/components/contacts/ContactSendModal.vue';
import SendTestModal from '@/components/mails/SendTestModal.vue';
import CreateGroupModal from '@/components/groups/CreateGroupModal.vue';
import EmailInput from '@/components/form/EmailInput.vue';
import Swal from 'sweetalert2';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import Emojis from '@/components/rebranding/Emojis.vue';
import datePicker from 'vue-bootstrap-datetimepicker';
import moment from 'moment';
import VueTagsInput from '@johmun/vue-tags-input';
import Page from '@/components/Page.vue';
import ContactService from '@/services/contact.service';
import Pagination from '@/components/rebranding/pagination/Pagination.vue';
import Loader from '@/plugins/loader';

const bucketApi = axios('buckets3');

export default {
  name: 'SendMail',
  components: {
    Page,
    SendTestModal,
    PageHeader,
    PreviewModal,
    CreateContactModal,
    ContactSendModal,
    GroupSendModal,
    CreateGroupModal,
    Emojis,
    // SelectInput,
    datePicker,
    // TemplateInput,
    VueTagsInput,
    EmailInput,
    // MultiUpload,
    // LottieAnimNoData,
    Pagination,
  },
  directives: {
    Tab,
  },
  data() {
    return {
      vars: [
        {
          name: 'Primerio Nome',
          value: 'first_name',
        },
        {
          name: 'Sobrenome',
          value: 'last_name',
        },
        {
          name: 'Email',
          value: 'email',
        },
        {
          name: 'Telefone',
          value: 'mobile_number',
        },
      ],
      breadcrumbLinks: {
        1: '/mail/reports/stat',
        2: '/mail/send',
      },
      file: {},
      products: [],
      fetchedGroups: true,
      selectedProduct: {},
      preview: false,
      domains: [],
      tags: [],
      file_name: null,
      shortCodes: [],
      addVars: false,
      addVarsSubject: false,
      hasVar: false,
      hasFile: false,
      groups: [],
      files: [],
      pageGroup: 1,
      pagesGroup: 1,
      selectedGroups: [],
      selectedContacts: [],
      groupsToAdd: [],
      selectedFiles: [],
      currentPageGroup: 1,
      totalPagesGroup: 1,
      formGroup: {
        page: 1,
      },
      isSending: false,
      fetchedContacts: false,
      contacts: [],
      contactsToAdd: [],
      page: 1,
      pages: 1,
      currentPage: 1,
      totalPages: 1,
      formContact: {
        page: 1,
      },
      dateOptions: {
        format: 'DD/MM/YYYY HH:mm',
        minDate: moment(),
        locale: 'pt',
        useCurrent: true,
        keepInvalid: true,
      },
      configEditor: {
        fullPage: true,
        allowedContent: true,
        extraPlugins: 'emoji',
      },
      importing: false,
      uploadProgress: 0,
      uploader: null,
      fileVariables: [],
      form: {
        productId: '',
        type: 'all',
        from: '',
        domain: '',
        to: '',
        body: '',
        from_name: '',
        placeholder: '',
        subject: '',
        reply_email: '',
        schedule: true,
        tags: [],
        attachment: [],
      },
      defaultMailBody: '<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">\n<html>\n<head>\n\t<title></title>\n</head>\n<body></body>\n</html>\n',
      unicode: false,
      replace: false,
      flash: false,
      segments: 1,
      submited: false,
      length: 0,
      features: [
        { text: 'Lista de contatos' },
        { text: 'Templates customizáveis' },
        { text: 'Relatórios em tempo real' },
        { text: 'Rastreio de cliques, aberturas e leituras de email' },
        { text: 'E muito mais...' },
      ],
      templates: [],
      fetchedTemplates: false,
      options: {
        format: 'DD/MM/YYYY HH:mm',
        useCurrent: true,
        minDate: moment(),
        locale: 'pt',
        keepInvalid: true,
      },
    };
  },
  computed: {
    locked() {
      if (this.$store.state.account.plans.email == null) {
        return true;
      }
      return false;
    },
    toDay() {
      return moment().format('DD/MM/YYYY HH:mm');
    },
    classes() {
      return ['wizard'];
    },
    account() {
      return this.$store.state.account;
    },
    user() {
      return this.$store?.state?.auth?.user;
    },
  },
  created() {
    this.fetchDomains();
    this.fetchProducts();
    this.feachTemplates();
    this.getGroups(1);
    this.getContacts(1);
    if (this.$route.params.contacts) {
      this.contacts = this.$route.params.contacts;
      this.form.type = 'all';
      this.contacts.forEach((contact) => {
        if (contact.email !== null && contact.email.length > 0) {
          this.form.tags.push({
            text: contact.email,
            id: contact.id,
            type: 'contact',
          });
        } else {
          this.$toast.show({
            title: 'Erro',
            content: `O contato ${contact.first_name} está sem email`,
            type: 'danger',
          });
        }
      });
    }

    if (this.$route.params.groups) {
      this.groups = this.$route.params.groups;
      this.form.type = 'all';
      this.groups.forEach((group) => {
        if (!this.selectedGroups.includes(group)) {
          this.form.tags.push({
            text: group.name,
            id: group.id,
            type: 'group',
          });
        }
      });
    }
    /* setTimeout(() => {
      console.log('Body value', this.form.body.length, 'Body value: ', this.form.body);
    }, 3000); */
  },
  methods: {
    removeAttachment() {
      this.files = [];
      this.hasFile = false;
      this.form.attachment = [];
      if (document.querySelector('.drop-img')) {
        document.querySelectorAll('.drop-img').forEach((element) => {
          element.remove();
        });
      }
      if (document.querySelector('.content-inside')) {
        document.querySelector('.content-inside').remove();
      }

      const contentUpload = document.querySelector('.content-upload');
      const contentInside = document.createElement('div');
      contentInside.classList.add('content-inside');
      const iconElement = document.createElement('span');
      iconElement.classList.add('material-symbols-outlined');
      iconElement.innerHTML = 'upload_file';
      const hElement = document.createElement('h4');
      hElement.innerHTML = 'Clique aqui ou arraste e solte';
      contentInside.append(iconElement);
      contentInside.append(hElement);
      contentUpload.append(contentInside);
    },
    onFilePicked(event) {
      this.hasFile = true;
      const files = event.target.files;
      if (document.querySelector('.drop-img')) {
        document.querySelector('.drop-img').remove();
      }
      if (document.querySelector('.content-inside')) {
        document.querySelector('.content-inside').remove();
      }
      files.forEach((file) => {
        const dropImg = document.createElement('div');
        dropImg.classList.add('drop-img');
        dropImg.classList.add('file-unic');
        dropImg.style = 'margin-top: 0px !important; height: 60px !important; width: 45% !important;';

        const span = document.createElement('span');
        span.classList.add('material-icons-outlined');
        const filesize = ((file.size / 1024) / 1024).toFixed(4); // MB
        if (filesize > 5) {
          this.$toast.show({
            title: this.$t('sms.infos.filters.status.lbl-error'),
            content: 'O arquivo é muito grande, o tamanho máximo é de 5MB',
            type: 'danger',
          });
          return;
        }
        this.files.push(file);
        this.form.attachment.push({
          file_type: file.type,
          size: file.size,
          name: file.name,
        });
        if (file.type.includes('image')) {
          span.innerHTML = 'image';
        } else if (file.type.includes('video')) {
          span.innerHTML = 'play_circle';
        } else if (file.type.includes('audio')) {
          span.innerHTML = 'music_note';
        } else {
          span.innerHTML = 'content_copy';
        }
        span.style = 'margin-top: 10px !important;';

        dropImg.append(span);
        this.$refs.dropz.append(dropImg);
      });
      // this.uploadAttachment();
    },
    uploadAttachment() {
      if (this.form.attachment.length > 0) {
        Loader.showModal('Salvando anexos...');
        const arrayPush = [];
        this.form.attachment.forEach(async (file, i) => {
          const formData = new FormData();
          formData.append('file', this.files[i]);
          formData.append('account_id', this.account.id);
          formData.append('user_id', this.user.id);
          const config = {
            headers: { 'Content-Type': 'multipart/form-data', 'X-API-SECRET': 'Odyg1JD2UgJKsCojwwDppA7PdYSasIAY' },
            onUploadProgress: (progressEvent) => {
              this.progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total,
              );
            },
          };
          arrayPush.push(
            MailService.uploadAttachMent(formData, config),
          );
        });
        Promise.all(arrayPush)
          .then(
            (responses) => {
              responses.forEach((response, i) => {
                this.form.attachment[i].url = response.data.path;
              });
              setTimeout(() => {
                Loader.hide();
                this.sendMail();
              }, 2000);
            },
            (error) => {
              this.content = error;
              this.progress = 0;
              this.$toast.show({
                title: this.$t('sms.infos.filters.status.lbl-error'),
                content: error.response.data.message,
                type: 'danger',
              });
            },
          )
          .finally(() => {
            // this.fetched = true;
          });
      }
    },
    onDeleteTag(event) {
      if (event.tag.type === 'file') {
        this.form.file = null;
      }
      event.deleteTag();
    },
    changeProgress(val) {
      const circle = document.getElementById('bar');
      const cont = document.getElementById('cont');

      const r = circle.getAttribute('r');
      const c = Math.PI * (r * 2);

      if (val < 0) {
        val = 0;
      }
      if (val > 100) {
        val = 100;
      }

      const pct = ((100 - val) / 100) * c;

      circle.style = `stroke-dashoffset: ${pct}`;

      cont.setAttribute('data-pct', val);
    },
    initials(firstname, lastname) {
      (firstname === null ? firstname = '' : firstname); // Avoiding displaying 'null' string
      (lastname === null ? lastname = '' : lastname); // Avoiding displaying 'null' string
      const emojiRegex = /\p{Emoji}/u;
      let fullname = '';
      if (firstname) {
        firstname = !firstname.match(emojiRegex) ? firstname.charAt(0).toUpperCase() : firstname.replace(emojiRegex, '').charAt(0).toUpperCase();
      }
      if (lastname) {
        lastname = !lastname.match(emojiRegex) ? lastname.charAt(0).toUpperCase() : lastname.replace(emojiRegex, '').charAt(0).toUpperCase();
      }
      fullname = firstname + lastname;

      return fullname;
    },
    searchContacts() {
      this.fetchedContacts = false;
      const word = this.$refs.searchInputContact.value;
      this.formContact.page = 1;
      ContactService.searchContacts(this.formContact, word).then(
        (response) => {
          this.fetchedContacts = true;
          this.contacts = response.data;
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
          this.fetchedContacts = true;
        },
      );
    },
    searchGroup() {
      this.fetchedGroups = false;
      const word = this.$refs.searchInput.value;
      // console.log('Query: ', word);
      let result = null;
      this.formGroup.page = 1;
      ContactService.getGroups(this.formGroup).then(
        (response) => {
          this.fetchedGroups = true;
          this.groups = response.data;
          result = this.groups.filter((item) => word.toLowerCase().split(' ').every((v) => item.name.toLowerCase().includes(v)));

          this.groups = result;
          this.pagesGroup = response.last_page;
        },
        (error) => {
          this.content = error;
          this.fetchedGroups = true;
        },
      );
    },
    getContacts(page) {
      this.formContact.page = page;
      this.currentPage = page;
      this.fetchedContacts = false;
      ContactService.getContacts(this.formContact).then(
        (response) => {
          this.fetchedContacts = true;
          this.contacts = response.data;
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
          this.fetchedContacts = true;
        },
      );
    },
    getGroups(page) {
      this.formGroup.page = page;
      this.currentPageGroup = page;
      this.fetchedGroups = false;
      ContactService.getGroups(this.formGroup).then(
        (response) => {
          this.fetchedGroups = true;
          this.groups = response.data;
          this.pagesGroup = response.last_page;
        },
        (error) => {
          this.fetchedGroups = true;
          this.content = error;
        },
      );
    },
    applyGroups() {
      this.form.contacts = [];
      this.form.type = 'all';
      this.groupsToAdd.forEach((group) => {
        if (!this.selectedGroups.includes(group)) {
          this.form.tags.push({
            text: group.name,
            id: group.id,
            type: 'group',
          });
        }
      });
      this.groupsToAdd = [];
      this.selectedGroups = [];
    },
    applyContacts() {
      this.form.contacts = [];
      this.form.type = 'all';
      this.contactsToAdd.forEach((contact) => {
        if (!this.selectedContacts.includes(contact)) {
          if (contact.email !== null && contact.email.length > 0) {
            this.form.tags.push({
              text: contact.email,
              id: contact.id,
              type: 'contact',
            });
          } else {
            this.$toast.show({
              title: 'Erro',
              content: `O contato ${contact.first_name} está sem email`,
              type: 'danger',
            });
          }
        }
      });
      this.selectedContacts = [];
      this.contactsToAdd = [];
    },
    changeTemplate(template) {
      this.form.body = template.body;
      this.countSegments();
    },
    feachTemplates() {
      this.fetchedTemplates = false;
      MailService.getTemplates({}).then(
        (response) => {
          this.fetchedTemplates = true;
          this.templates = response;
        },
        (error) => {
          this.content = error;
          this.fetchedTemplates = true;
          this.$toast.show({
            title: 'Erro',
            content: 'Erro ao requisitar templates.',
            type: 'danger',
          });
        },
      );
    },
    searchTemplates() {
      this.fetchedTemplates = false;
      const word = this.$refs.searchTemplatesInput.value;
      const formTemplate = {
        page: 1,
        name: {
          lk: word,
        },
      };
      MailService.getTemplates(formTemplate).then(
        (response) => {
          this.fetchedTemplates = true;
          this.templates = response.data;
        },
        (error) => {
          this.content = error;
          this.fetchedTemplates = true;
          this.$toast.show({
            title: 'Erro',
            content: 'Erro ao requisitar templates.',
            type: 'danger',
          });
        },
      );
    },
    subscribeMail() {},
    hideTemplate(template) {
      template.show = false;
      this.$forceUpdate();
    },
    showTemplate(template) {
      template.show = true;
      this.$forceUpdate();
    },
    uploadAll() {
      this.$refs.uploader.launch();
    },
    async readFile(file, encoding) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          this.fileVariables = [];
          const lines = reader.result.split(/\r\n|\n|\r/);
          const total = lines.length;

          // if (total > 30) {
          if (total > 1000001) {
            const error = `${this.$t('generic-str.archive')} ${
              file.name
            } ${this.$t('warnings.exceed')}}`;

            file.status = 'error';
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error,
              type: 'danger',
            });

            reject(error);
          } else {
            this.vars = lines
              .shift()
              .split(';')
              .map((variable) => ({
                value: variable,
                name: variable,
              }));

            resolve();
          }
        };
        reader.readAsText(file.source, encoding);
      });
    },
    async validateCurrentFile(file) {
      return this.readFile(file, 'UTF-8');
    },
    async uploadCurrentFile(file) {
      this.importing = true;

      const formData = new FormData();

      formData.append('file', file.source);

      const config = {
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          if (progress > 5) file.progress = progress;
        },
      };

      return axios()
        .post('email/files', formData, config)
        .then(
          () => {
            file.status = 'completed';
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('warnings.send-component.success'),
              type: 'success',
            });
          },
          (error) => {
            file.status = 'error';
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.importing = false;
          this.uploadProgress = 0;
        });
    },
    uploadCompleted() {
      // console.log('[this.$refs.filenames]', this.$refs.uploader.filenames);
      this.form.files = this.$refs.uploader.filenames;
    },
    fetchDomains() {
      MailService.getDomains({
        valid: true,
      }).then(
        (domains) => {
          this.loading = false;
          this.domains = domains;
          this.form.from = '';

          if (domains && domains.length) {
            this.form.domain = domains[0].domain;
          }
        },
        (error) => {
          this.content = error;
        },
      );
    },
    fetchProducts() {
      MailService.getProducts().then(
        (products) => {
          this.products = products;
          if (products) {
            this.selectedProduct = products[0];
            this.form.productId = this.selectedProduct.id;
          }
        },
        (error) => {
          this.content = error;
        },
      );
    },
    changeProduct(product) {
      this.selectedProduct = product;
    },
    mountPreview() {
      this.$root.$emit('updated.previewmail', { body: this.form.body });
    },
    async mountTest() {
      if (this.form.subject === '') {
        this.$toast.show({
          title: this.$t('sms.infos.filters.status.lbl-error'),
          content: this.$t('warnings.send-component.subject'),
          type: 'danger',
        });
        return;
      }

      this.form.from = this.form.from_t;

      if (
        this.form.from === null ||
        this.form.from === '' ||
        this.form.from === undefined
      ) {
        this.$toast.show({
          title: this.$t('sms.infos.filters.status.lbl-error'),
          content: this.$t('warnings.send-component.invalid-sender'),
          type: 'danger',
        });
        return;
      }

      if (this.domains.length > 0) {
        this.form.from = `${this.form.from_t}@${this.form.domain}`;
        if (!MailService.validateEmail(this.form.from)) {
          this.$toast.show({
            title: this.$t('sms.infos.filters.status.lbl-error'),
            content: this.$t('warnings.send-component.invalid-sender'),
            type: 'danger',
          });
          return;
        }
      }

      if (this.form.datetime) {
        this.form.scheduled_at = moment(
          this.form.datetime,
          'DD/MM/YYYY HH:mm:ss',
        ).format('YYYY-MM-DD HH:mm:ss');
      }

      await this.$refs['send-test-modal'].show();

      this.$root.$emit('updated.testmail', {
        from: this.form.from,
        body: this.form.body,
        from_name: this.form.from_name,
        subject: this.form.subject,
        reply_email: this.form.reply_email,
      });
    },
    removeSpaces(event) {
      const el = event.target;
      el.value = el.value.replace(/\s+/g, '');
    },
    putEmoji(emoji) {
      if (this.form.subject.length) {
        this.form.subject += ` ${emoji}`;
      } else {
        this.form.subject = emoji;
      }
    },
    selectedGroupsChange(param) {
      param.selectedGroups.forEach((group) => {
        if (!this.selectedGroups.includes(group.id)) {
          this.groups.push(group);
          this.selectedGroups.push(group.id);
        }
      });
    },
    toBottom() {
      window.onscroll = () => {
        const bottomOfWindow =
          Math.max(
            window.pageYOffset,
            document.documentElement.scrollTop,
            document.body.scrollTop,
          ) +
            window.innerHeight ===
          document.documentElement.offsetHeight;
        if (bottomOfWindow) {
          this.scrolledToBottom = true; // replace it with your code
        }
      };
    },
    selectedContactsChange(param) {
      param.selectedContacts.forEach((contact) => {
        if (!this.selectedContacts.includes(contact.email)) {
          this.contacts.push(contact);
          this.selectedContacts.push(contact.id);
        }
      });
    },
    selectedFilesChange(param) {
      param.selectedFiles.forEach((file) => {
        this.selectedFiles[0] = file.name;
        this.files[0] = file;
        // if (!this.selectedFiles.includes(file.id)) {
        // this.files.push(file);
        // this.selectedFiles.push(file.id);
        // }
      });
    },
    changeType(type) {
      // console.log({ type });

      if (type === 'contacts' || type === 'groups') {
        this.vars = [
          { name: this.$t('generic-str.lbl-first-name'), value: 'first_name' },
          { name: this.$t('generic-str.lbl-last-name'), value: 'last_name' },
          {
            name: this.$t('generic-str.lbl-cellphone'),
            value: 'mobile_number',
          },
          { name: 'Email', value: 'email' },
        ];
      } else if (type === 'file') {
        this.vars = this.fileVariables;
        const check = setInterval(() => {
          // console.log('this.uploader', this.uploader);
          if (type !== 'file' || this.uploader !== null) clearInterval(check);
          if (this.$refs.uploader) this.uploader = this.$refs.uploader;
        }, 500);
      } else {
        this.vars = [];
      }
      this.form.type = type;
      this.submited = false;
    },
    openFileUpload() {
      this.$refs.uploadedFile.click();
    },
    uploadFile(event) {
      this.changeProgress(0);
      const self = this;
      const reader = new FileReader();
      this.importing = true;
      const formData = new FormData();
      const file = event.target.files[0];
      formData.append('file', file);
      this.form.file = file.name;
      this.form.tags.push({
        text: file.name,
        file: file.name,
        type: 'file',
      });
      this.form.type = 'all';

      reader.onload = () => {
        self.fileVariables = [];
        const variables = reader.result
          .split('\n')
          .shift()
          .replace('\r', '')
          .split(';'); // first line
        variables.forEach((variable) => {
          self.fileVariables.push({
            value: variable,
            name: variable,
          });
        });
        self.vars = self.fileVariables;
      };
      reader.readAsText(file, 'UTF-8');

      const config = {
        onUploadProgress: (progressEvent) => {
          this.uploadProgress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          this.changeProgress(this.uploadProgress);
        },
      };

      axios()
        .post('email/files', formData, config)
        .then(
          () => {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('warnings.send-component.success'),
              type: 'success',
            });
          },
          (error) => {
            self.form.file = '';
            self.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.importing = false;
          this.uploadProgress = 0;
        });
    },
    removeGroup(i) {
      this.groups.splice(i, 1);
      this.selectedGroups.splice(i, 1);
      this.$root.$emit('groups.selected.refresh', {
        selectedGroups: this.groups,
      });
    },
    removeContact(i) {
      this.contacts.splice(i, 1);
      this.selectedContacts.splice(i, 1);
      this.$root.$emit('contacts.selected.refresh', {
        selectedContacts: this.contacts,
      });
    },
    removeFile(i) {
      this.files.splice(i, 1);
      this.selectedFiles.splice(i, 1);
    },
    addVar(entity, to) {
      if (to === 'subject') {
        this.addVarsSubject = false;
        this.form.subject = this.form.subject.concat(` {${entity}} `);
      } else {
        this.addVars = false;
        this.form.body = this.form.body.concat(` {${entity}} `);
      }
    },
    showAddVar() {
      this.addVars = true;
    },
    hideAddVar() {
      this.addVars = false;
    },
    insertTemplate(template) {
      this.form.body = template;
      this.countSegments();
      // form.body = e.target.value;
    },
    send() {
      if (this.form.datetime) {
        const scheduledAt = moment(
          this.form.datetime,
          'DD/MM/YYYY HH:mm:ss',
        ).format('YYYY-MM-DD HH:mm:ss');
        this.form.scheduled_at = scheduledAt;
      }
      if (this.form.subject === '') {
        this.$toast.show({
          title: this.$t('sms.infos.filters.status.lbl-error'),
          content: this.$t('warnings.send-component.subject'),
          type: 'danger',
        });
        return;
      }
      this.form.from = this.form.from_t;
      if (
        this.form.from === null ||
        this.form.from === '' ||
        this.form.from === undefined
      ) {
        this.$toast.show({
          title: this.$t('sms.infos.filters.status.lbl-error'),
          content: this.$t('warnings.send-component.invalid-sender'),
          type: 'danger',
        });
        return;
      }

      if (this.domains.length > 0) {
        this.form.from = `${this.form.from}@${this.form.domain}`;
        if (!MailService.validateEmail(this.form.from)) {
          this.$toast.show({
            title: this.$t('sms.infos.filters.status.lbl-error'),
            content: this.$t('warnings.send-component.invalid-sender'),
            type: 'danger',
          });
          return;
        }
      }
      this.submited = true;
      if (this.form.type === 'all') {
        if (this.form.tags.length > 0 && this.form.body.length > 0) {
          this.sendRequest();
        } else {
          this.$toast.show({
            title: this.$t('sms.infos.filters.status.lbl-error'),
            content: this.$t('warnings.send-component.field'),
            type: 'danger',
          });
        }
      }
    },
    changeSchedule() { },
    sendRequest() {
      Swal.fire({
        title: this.$t('generic-str.menu.send-msg'),
        text: this.$t('warnings.send-component.send-confirm'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        this.toBottom();
        if (result.value) {
          this.isSending = true;
          if (this.hasFile) {
            this.uploadAttachment();
          } else {
            this.sendMail();
          }
        }
      });
    },
    sendMail() {
      MailService.sendMail(this.form)
        .then(
          (send) => {
            this.form = {
              productId: '',
              type: 'simple',
              from: '',
              domain: '',
              to: '',
              body: '',
              from_name: '',
              placeholder: '',
              subject: '',
              reply_email: '',
              schedule: true,
              attachment: [],
            };
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('warnings.send-component.msg-sent'),
              type: 'success',
            });
            this.isSending = false;
            this.removeAttachment();
          },
          (error) => {
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
            this.isSending = false;
          },
        )
        .catch(() => {
          this.isSending = false;
        });
    },
  },
  mounted() {
    this.$root.$on('groups.addonsend', this.selectedGroupsChange);
    this.$root.$on('contacts.addonsend', this.selectedContactsChange);
    this.$root.$on('files.addonsend', this.selectedFilesChange);
    /*  this.$root.$on('bv::dropdown::show', (bvEvent) => {
      if (bvEvent.componentId === 'dropdown-2') {
        this.isDropdown2Visible = true;
      }
    });
    this.$root.$on('bv::dropdown::hide', (bvEvent) => {
      if (bvEvent.componentId === 'dropdown-2') {
        this.isDropdown2Visible = false;
      }
      if (this.isDropdown2Visible) {
        bvEvent.preventDefault();
      }
    }); */
  },
};
</script>

<style scoped lang="scss">
/* .modal {
  z-index: 1000;
}

.modal-backdrop {
  z-index: 999;
} */

i {
  color: inherit;
}

.input-group.domain {
  .v-select {
    padding: 0;
  }

  .vs__dropdown-toggle {
    border: none !important;
  }
}

textarea:not(:last-child) {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.textarea-information {
  /* border: 1px solid #dfe7f3;
  border-top: none;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; */
  padding: 0.2rem 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
/* textarea:focus + .textarea-information {
  border: 1px solid #635ebe;
  border-top: none;
} */
.input-date {
  margin-top: 15px !important;
}
.form-control.invalid {
  border-color: #ff5c75;
}
.actions-var {
  position: relative !important;
}
.emoji-color {
  color: #a6a6a6 !important;
}
.second-toolbar #logo {
  visibility: hidden;
}
.fr-wrapper > *:first-child {
  display: none;
}
.fr-wrapper .fr-element {
  display: block;
}
.send-group-card {
  box-shadow: none !important;
}
.send-group-card .table-placeholder i,
h5 {
  color: #b5b5b5;
}
.actions-var-cancel {
  text-align: center;
  color: #4d5a68;
  border-top: 1px solid rgba(234, 243, 253, 0.7);
  padding-top: 5px;
  padding-bottom: 0px;
}
.actions-icon {
  font-size: 14px !important;
}
.actions-var a {
  cursor: pointer;
}
.custom-control-inline-send {
  margin-left: 0px !important;
  padding-left: 0px !important;
}
.label-send-check {
  margin-left: 10px;
  margin-top: 2px;
}
.ul-list-groups {
  margin: 0px;
  padding: 0px;
}
.ul-list-groups li {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.subjectvar {
  position: absolute !important;
  top: 6px;
  right: 55px;
}
/* ::v-deep .ti-input {
  border-color: #dfe7f3 !important;
  border: 1px solid #dfe7f3 !important;
  width: 100%;
  border-radius: 0.25rem !important;
} */
.vue-tags-input {
  max-width: 100% !important;
  width: 100% !important;
}
#cke_673 {
  display: none !important;
}
.cke_toolbar_last {
  display: none;
}

/* Rebranding */
::v-deep .ti-input {
  border: unset !important;
}

.darkmode .card-send-content .card {
  background-color: var(--background-3);
}

::v-deep #sidebar-right-template {
  background-color: var(--background-2) !important;
  width: 525px;

  small {
    color: var(--gray-font-color);
  }

  // Default sidebar header
  .b-sidebar-header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    button.close {
      margin-left: auto;
      margin-right: unset;
    }
  }

  // Custom sidebar header
  .sidebar-header {
    display: flex;
    align-items: center;
    padding-top: 2.5rem;

    p {
      font-weight: 500;
      font-size: 1.25rem;
    }

    span {
      color: var(--gray-font-color-5);
    }

    span.sidebar-close {
      position: absolute;
      top: 12px;
      right: 0;
    }

    .arrow-back {
      position: absolute;
      top: 12px;
      left: -10px;
    }
  }

  .b-sidebar-body {
    padding: 0 1.5rem;
  }

  header {
    >strong {
      order: -1;
    }
  }

  .desc-column {
    p {
      color: var(--gray-font-color-4);
    }
  }
}

::v-deep #sidebar-right-domain {
  background-color: var(--background-2) !important;
  width: 525px;

  small {
    color: var(--gray-font-color);
  }

  // Default sidebar header
  .b-sidebar-header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    button.close {
      margin-left: auto;
      margin-right: unset;
    }
  }

  // Custom sidebar header
  .sidebar-header {
    display: flex;
    align-items: center;
    padding-top: 2.5rem;

    p {
      font-weight: 500;
      font-size: 1.25rem;
    }

    span {
      color: var(--gray-font-color-5);
    }

    span.sidebar-close {
      position: absolute;
      top: 12px;
      right: 0;
    }

    .arrow-back {
      position: absolute;
      top: 12px;
      left: -10px;
    }
  }

  .b-sidebar-body {
    padding: 0 1.5rem;
  }

  header {
    >strong {
      order: -1;
    }
  }

  .desc-column {
    p {
      color: var(--gray-font-color-4);
    }
  }
}
.card.card-tabs .card-header .nav.nav-tabs {
  width: 100%;
  padding: 0 1rem;
  gap: 2.5rem;
}
.card.card-tabs .card-header .nav.nav-tabs li a {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 1.4rem 0.7rem 1.3rem;
  color: var(--clr-light-2);
}
.nav.nav-tabs .nav-item .nav-link.active {
  color: var(--clr-yup-purple) !important;
  border-bottom: solid 2px var(--clr-yup-purple);
}

.nav.nav-tabs .nav-item .nav-link.active i {
  color: var(--clr-yup-purple) !important;
}

.card.card-tabs .card-header .nav.nav-tabs li a i {
  font-size: 24px;
  color: var(--clr-light-2);
}

.nav-tabs .nav-item {
  margin-bottom: -2px;
}
.nav-tabs {
  border-bottom: 2px solid rgba(210, 221, 234, 0.5);
}
.send-opt {
  color: var(--gray-font-color);
}

.send-opt .control-checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
}

.send-opt .control span {
  margin-top: 5px;
}

.card-footer-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 1rem 0;
  border-top: 2px solid #00000029;
}

/* .right-items {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  width: fit-content;
  margin-left: auto;
} */

.right-items::v-deep .dropdown-menu .dropdown-item {
  color: var(--clr-yup-purple);
}

.card-footer-wrapper i.title,
.card-footer-wrapper span.title {
  font-size: 1.4rem;
  transition: 0.5s;
  padding: 9px;
}

.card-footer-wrapper span.attach {
  transform: rotateY(180deg);
}

.card-footer-wrapper i.title:hover,
.card-footer-wrapper span.title:hover {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.recipient-wrapper,
#recipients-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

#recipients-list li {
  background-color: #fff;
  padding: 5px;
  border-radius: 10px;
  border: 2px solid #f7f7f7;
}

.darkmode #recipients-list li {
  border-color: var(--background-3);
  background-color: var(--background-2);
}

#recipients-list li .delete-item {
  font-size: 0.7rem;
  padding: 3px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.5s;
}

#recipients-list li .delete-item:hover {
  background-color: rgba(210, 221, 234, 0.5);
}

.darkmode #recipients-list li .delete-item:hover {
  background-color: var(--background-3);
}

/* .dropup::v-deep ul.dropdown-menu.show {
  top: -180% !important;
} */

.dropup::v-deep ul.dropdown-menu {
  width: fit-content;
}

.dropup::v-deep ul.dropdown-menu a.dropdown-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.template-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.template-wrapper label {
  padding-right: 3rem;
}

.form-floating.floating-transparent textarea,
.form-floating.floating-transparent input {
  border-color: transparent !important;
  /* border-bottom: 2px solid #00000029 !important; */
  padding-left: 0;
  margin-left: 0.8rem;
  background-color: var(--background-2);
}

/* .form-floating.floating-transparent input {
  border-color: transparent !important;
} */

.form-floating label {
  margin-top: unset !important;
}

.template-style {
  font-size: 0.8rem;
}

.v-select::v-deep > div:first-child,
.campaign-input,
.form-file {
  width: 100%;
}

::v-deep .product-input select {
  width: 320px;
}

.sender-group,
.template-wrapper {
  margin-bottom: 1.5rem;
}

.campaign-group,
.product-group,
.recipient-group {
  margin-bottom: 3rem;

  input.form-control {
    border-color: transparent !important;
  }

  /* .recipient-column > span {
    font-weight: 400;
  } */
}

.sender-input input.form-control {
  border-color: transparent !important;
}

::v-deep label {
  color: var(--gray-font-color-2) !important;
  font-weight: 400;
}
::v-deep .vs__search {
  position: absolute !important;
}

.preview-div {
  display: flex;
  position: relative;
  /* align-items: center; */
}

.preview-wrapper {
  width: 100%;
  height: fit-content;
}

.phone-img {
  margin-left: auto;
  width: 20rem;
  height: fit-content;
}

.add-contacts {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.btn_add {
  width: fit-content;
  height: auto;
  padding: 0;
  margin-left: auto;
}
hr {
  width: 100%;
  border-top: 2px solid #00000029;
}
.btn-import {
  width: fit-content;
  height: auto;
  padding: 0;
  margin-left: 1.25rem;
}
.btn-import span {
  color: var(--gray-font-color);
  font-weight: 300;
}
.archive-opt {
  display: flex;
  align-items: center;
}
.darkmode .form-floating.floating-transparent textarea {
  background-color: var(--background-2);
}

.darkmode .nav-tabs .nav-link.active {
  background-color: var(--background-3);
}

.darkmode .nav.nav-tabs .nav-item .nav-link:hover,
.darkmode .nav.nav-tabs .nav-item .nav-link:hover i {
  color: #fff;
}

.nav-send-type {
  gap: 1rem;
}

.nav-send-type li {
  cursor: pointer;
}
.nav-send-type li a {
  color: var(--gray-font-color) !important;
  padding: 8px;
  transition: 0.5s;
  display: flex;
  align-items: center;
}

.nav-send-type li a:hover,
.nav-send-type li a.active {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}
.darkmode .nav-send-type li a {
  background-color: var(--background-3);
}
.nav-send-type li i {
  font-size: 24px;
  color: var(--gray-font-color) !important;
}
.message-type-title {
  display: flex;
  flex-wrap: wrap;
}
.message-type-title .text-right {
  margin-left: auto;
}
::v-deep .textarea-information {
  background-color: var(--background-3) !important;
  border-color: var(--background-3) !important;
}
::v-deep .emojis__widget {
  box-shadow: 0px 3px 6px #00000029 !important;
}
.emoji-variable-wrapper {
  margin-left: 5px;
  display: flex;
  align-items: center;
}
.card-send-content textarea {
  border-color: transparent !important;
}
.card-cover {
  padding: 6px;
}
.card-cover > img {
  width: 320px;
  border-radius: 3px;
}
.title_wrapper,
.text_wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.text_wrapper {
  margin-bottom: 12px;
}
.edit_square_icon {
  margin-left: auto;
  color: var(--gray-font-color);
  cursor: pointer;
  transition: 0.5s;
  padding: 5px;
}
.edit_square_icon:hover {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}
.actions_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.actions_wrapper span {
  margin-left: unset !important;
}
.actions_wrapper ~ .actions_wrapper {
  margin-top: 12px;
}
.edit_absolute {
  right: 10px;
  color: #fff;
  cursor: pointer;
  transition: 0.5s;
  mix-blend-mode: difference;
}
.edit_absolute:hover {
  opacity: 0.5;
}
.edit_action_wrapper {
  margin-bottom: 12px;
}
.opt-carousel-card {
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
  flex-wrap: wrap;
  gap: 10px;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
.main-content-card {
  transition: all ease-in-out 0.5s;
  max-width: 300px;
}
.card-extra-opt:hover .main-content-card {
  transform: translateX(-40px);
}
.card-extra-opt {
  overflow: hidden;
  position: relative;
}
.extra-opt {
  transition: all ease-in-out 0.5s;
}
.card-extra-opt:hover .extra-opt {
  transform: translateX(-40px);
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.more-opt {
  position: absolute;
  right: -30px;
  top: 50%;
  padding: 6px;
  transition: 0.5s;
  z-index: 5;
}

.card-extra-opt:hover .more-opt {
  right: 0;
}

.show-extra-opt,
.card.card-extra-opt {
  /* width: 600px !important; */
  flex-direction: row;
  flex-shrink: 0;
}

.extra-configs {
  width: 300px;
  height: 100%;
}

.not-collapsed ~ .main-content-card {
  /* left: -85px;
  margin-left: -30px; */
  max-width: 300px;
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
.extra-opt {
  overflow: auto;
  max-height: 350px;
}
.c-gallery.accordion {
  scroll-snap-type: unset !important;
  scroll-behavior: unset !important;
}

.card {
  transition: all ease-in-out 0.5s;
}

.c-gallery.accordion.highlight-card > :not(.show-card) {
  width: 220px;
  min-width: unset;
}

.card.card-extra-opt {
  width: 30%;
}

.card.card-extra-opt.show-card {
  width: 630px;
}

.title-wrapper,
.view-type {
  display: flex;
}

.view-type {
  margin-left: auto;
}

.view-type span {
  cursor: pointer;
  color: var(--gray-font-color) !important;
  padding: 8px;
  display: flex;
  align-items: center;
}

.view-type span:hover {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.grid-view {
  flex-wrap: wrap;
}

::v-deep .vue-tags-input,
::v-deep .ti-new-tag-input-wrapper input {
  background-color: var(--background-2) !important;
  color: var(--foreground);
}

.darkmode .vs__open-indicator {
  fill: var(--foreground);
}
.input-group-text {
  background: #fff;
  border-left: 0px;
  border-right: 0px;
}
.darkmode .sender-input .input-group-text {
  background-color: var(--background-3) !important;
  border-color: var(--background-3) !important;
  color: var(--foreground);
}

.vue-tags-input.vue-tag-borderless .ti-input {
  border-color: transparent !important;
}

.card-preview.default-message {
  width: 410px;
  top: 200px;

  .card-body{
    padding: 3rem 1rem;
  }
}

.preview-iframe {
  transform: scale(0.72);
  transform-origin: top left;
  height: 372px;
  width: 360px;

  iframe {
    border: none;
    width: 100%;
    height: 100%;
    min-height: 512px;
  }
}

.input-group.domain {

  .v-select {
    height: 100%;
    display: flex;

    .vs__dropdown-toggle {
      width: 100%;
    }
  }
}

.sender-input {
  // margin-bottom: 1rem;
  .form-floating {
    input {
      padding-top: 5px;
      padding-bottom: 5px;
      margin-left: 0;
      height: 40px;
    }
    label {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;
      margin-bottom: 0;
    }
  }
}

.border-input {
  border-bottom: 2px solid #00000029;
  padding-left: 0;
}

@media (min-width: 1360px) and (max-width: 1600px) {
  .card.card-extra-opt {
    width: 40%;
  }
}

@media (min-width: 768px) {
  ::v-deep label {
    font-size: 1rem !important;
  }
  .phone-img {
    position: absolute;
    right: 0;
    padding-right: 3rem;
    margin-top: 10%;
    z-index: -1;
  }
  .preview-wrapper {
    position: sticky;
    top: 100px;
  }
  .card-preview {
    position: absolute;
    width: 290px;
    top: 130px;
    right: 20%;
  }
  .card-preview .card-body {
    padding: .72rem 1rem;
  }

  .card.card-preview img {
    object-fit: cover;
    max-height: 200px;
  }

  .dropup::v-deep ul.dropdown-menu {
    width: fit-content;
  }

  .dropup::v-deep ul.dropdown-menu a.dropdown-item {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .right-items {
    display: flex;
    align-items: center;
  }

  .right-opt {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  ::v-deep .dropdown-menu .dropdown-item {
    color: var(--clr-yup-purple);
  }

  ::v-deep .nested-drop {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .calendar_form {
  position: absolute;
  right: 150px !important;
  width: 150px !important;

  input.input-date {
    opacity: 0;
  }
}

.calendar_form:hover~.calendar_icon,
.calendar_form:focus-within~.calendar_icon {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.calendar_form input {
  cursor: pointer;
  width: 150px;
}

  ::v-deep .bootstrap-datetimepicker-widget * {
    opacity: 1 !important;
    z-index: 99999999 !important;
  }

  .campaign-row input.form-control {
    border-color: transparent !important;
  }
}
@media (max-width: 767.98px) {
  .calendar_form {
    position: absolute;
    right: 112px;
    width: 25px;
    input.input-date {
      position: relative;
      opacity: 0;
      padding: unset;
      height: auto;
      margin-top: 0 !important;
      z-index: 5;
    }
  }
  .card-tabs .nav-link span:last-of-type:not(.card-tabs .nav-link.active span) {
    display: none;
  }
  .card.card-tabs .card-header .nav.nav-tabs li a {
    transform: scale(1.1);
    height: 40px;
    padding: 20px 5px !important;
    padding-top: 30px !important;
  }
  .card.card-tabs .card-header .nav.nav-tabs {
    justify-content: space-between;
    gap: 5px !important;
  }
  .rebranding .card .opts_card {
    justify-content: flex-end;
    margin-left: unset !important;
    width: 100%;
    padding-top: 10px;
  }
  .rebranding .card .opts_card span {
    font-size: 26px;
    padding-top: 0;
  }

  .form-file {
    width: 80%;
  }

  ::v-deep .input_campaign {
    border-bottom: 2px solid !important;
    border-color: transparent !important;
    border-bottom-color: #00000029 !important;
    margin-bottom: 1rem;
  }
  .recipient-group {
    margin-bottom: 1.5rem;
  }
  .card-preview.default-message {
    top: 0;
  }

  .right-opt {
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }
  .calendar_icon {
    position: relative;
    margin-left: auto;
  }
  ::v-deep .bootstrap-datetimepicker-widget {
    inset: auto -100px 30px auto !important;
    /* position: absolute !important;
    right: 0 !important; */
  }
}
.close-input {
  position: absolute;
  top: 8px;
  font-size: 20px;
  right: 10px;
}

@media (max-width: 1301px) {
  .card-header-left {
    width: 50%;
  }

  .card-header-right {
    width: 45%;
    padding-left: 10%;
  }
}

.card-header-left {
  width: 72%;
}

.info-whatsapp-button {
  display: flex;
  flex-direction: row;
}

.info-whatsapp-button .card-header-sync {
  margin-top: 4px;
}

.info-whatsapp-button .card-header-number {
  margin-left: 5px;
}

.info-var {
  color: #16CD08;
}

.info-var-tip {
  color: #6B23DC;
}

.info-var-tip-text {
  color: #AAA;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding-top: 5px;
}

.var-name-span {
  color: #AD1519;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.btn-files {
  border-left: 1px #E5E5E5 solid;
  padding-top: 5px;
  margin-top: 15px;
}

.btn-files span {
  color: #697077;
  margin-right: 15px;
  cursor: pointer;
}

.btn-files span:hover {
  color: #ccc;
}

::v-deep .tags-container {
  .ti-input {
    border: none;
    padding: 0;
  }

  .form-control-send {
    padding: 3px !important;
    height: auto !important;
  }
  .vue-tags-input {
    min-height: 100px;
  }
}

.select-agent {
  margin-top: 10px;
  width: 58%;
  border-radius: 8px !important;
  border: 2px solid #E5E5E5 !important;
  color: #b0b0b0;
}
.send-group-card .table-placeholder i,
h5 {
  color: #b5b5b5;
}

.actions-var-cancel {
  text-align: center;
  color: #4d5a68;
  border-top: 1px solid rgba(234, 243, 253, 0.7);
  padding-top: 5px;
  padding-bottom: 0px;
}

.actions-icon {
  font-size: 14px !important;
}

.actions-var a {
  cursor: pointer;
}

.custom-control-inline-send {
  margin-left: 0px !important;
  padding-left: 0px !important;
}

.label-send-check {
  margin-left: 10px;
  margin-top: 2px;
}

.ul-list-groups {
  margin: 0px;
  padding: 0px;
}

.ul-list-groups li {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.var-insede-input {
  margin-left: 5px;
  position: absolute;
  right: 5px;
  top: 5px;
}

.var-insede-input i {
  font-size: 15px !important;
}

/* Rebranding */
.card.card-tabs .card-header .nav.nav-tabs {
  width: 100%;
  padding: 0 1rem;
  gap: 2.5rem;
}

.card.card-tabs .card-header .nav.nav-tabs li a {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 1.4rem 0.7rem 1.3rem;
  color: var(--clr-light-2);
}

.nav.nav-tabs .nav-item .nav-link.active {
  color: var(--clr-yup-purple) !important;
  border-bottom: solid 2px var(--clr-yup-purple);
}

.nav.nav-tabs .nav-item .nav-link.active i {
  color: var(--clr-yup-purple) !important;
}

.card.card-tabs .card-header .nav.nav-tabs li a i {
  font-size: 24px;
  color: var(--clr-light-2);
}

.nav-tabs .nav-item {
  margin-bottom: -2px;
}

.nav-tabs {
  border-bottom: 2px solid rgba(210, 221, 234, 0.5);
}

/* .send-opt li .control-group .control_indicator {
  float: left;
  margin-right: 10px;
} */

.send-opt {
  color: var(--gray-font-color);
}

.send-opt .control-checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
}

.send-opt .control span {
  margin-top: 5px;
}

.card-footer-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 1rem 0;
  border-top: 2px solid #00000029;
}

.right-items {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  width: fit-content;
  margin-left: auto;
}

.right-items::v-deep .dropdown-menu .dropdown-item {
  color: var(--clr-yup-purple);
}

.card-footer-wrapper i.title,
.card-footer-wrapper span.title {
  font-size: 1.4rem;
  transition: 0.5s;
  padding: 9px;
}

.card-footer-wrapper span.attach {
  transform: rotateY(180deg);
}

.card-footer-wrapper i.title:hover,
.card-footer-wrapper span.title:hover {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}
.card {
  border: 1px solid #E5E5E5;
  box-shadow: none;
}

.card-header {
  padding: 10px 18px !important;
  background: #F2F2F2;
  border-radius: 8px 8px var(8px, 0px) var(8px, 0px);
}

.card-hours-span {
  color: #AAA;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  width: 72%;
}

.card-header-right {
  display: flex;
  width: 25%;
  flex-direction: row;
  cursor: pointer;
}

.card-header-right:hover span {
  color: #312b2b;
}

.card-header-right:hover #light-svg {
  fill: #312b2b;
}

.card-header-right span {
  color: #595959;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  padding-top: 2px;
  margin-left: 5px;
}

.info-icons {
  color: #AAAAAA;
  font-size: 24px;
  margin-top: 5px;
  margin-left: 15px;
}

.form-control-send {
  padding: 10px 8px !important;
  border-radius: 8px !important;
  border: 2px solid #E5E5E5 !important;
}
.flex-row {
  flex-direction: row;
  display: flex;
}
.flex {
  display: flex !important;
}

.card-header-photo {
  background: #003969;
  width: 30px;
  height: 30px;
  border-radius: 15px;
}

.card-header-name {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #0D0519;
}
.card-header-number {
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  text-decoration-line: underline;
  color: #595959;
}

.card-header-number-text {
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #AAA;
}

.card-header-sync {
  font-size: 16px;
  color: #697077;
  display: inline-block;
}
.text-right {
  text-align: right !important;
}

.send-button {
  padding: 6px 24px;
  border-radius: 8px;
  background: #6B23DC;
  color: #fff;
  border: none;
  margin-top: 15px;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500 !important;
  margin-left: 22px;
}

.send-button-outline {
  background: #fff;
  color: #6B23DC;
  border: 1px solid #6B23DC;
}

.send-button:hover {
  background: #732be6;
}

.send-button-outline:hover {
  background: #e4d8f7;
}
.reply-input {
  border-top-right-radius: 0!important;
  border-bottom-right-radius: 0 !important;
}
.icon-sends {
  padding-left: 10px !important;
}

.icon-sends span {
  margin-top: 4px;
  color: #697077;
  font-size: 24px;
  cursor: pointer;
  margin-right: 10px;
  display: inline-block;
}

.icon-sends span:hover {
  color: #3e4042;
}
.divider-form {
  width: 100%;
  height: 4px;
  background: rgba(107, 35, 220, 0.24);
  margin-left: 10px;
  margin-right: 10px;
}
.w68 {
  padding-right: 5px;
  flex: 0 0 68.666667%;
  max-width: 68.666667%;
}
.card-preview {
  width: 60%;
}
.btn-add {
  display: flex;
  align-items: center;
  justify-content: center;
  width: min-content;
  background-color: #6B23DC;
  color: #FFF;
  font-weight: 400;
  border-radius: 8px;
  font-size: 1rem;
}

.search-input {
  width: 100%;
  margin-bottom: 22px;
}

.search-input input {
  height: 50px;
}

.search-input span {
  position: absolute;
  top: 12px;
  right: 10px;
}

.search-input span:hover {
  color: #ccc;
}
.row-list {
  display: flex;
  width: 100%;
  margin: 0px;
  padding: 16px;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid #E5E5E5;
  margin-bottom: 10px;
}

.row-list .material-symbols-outlined {
  font-size: 21px;
  margin-right: 8px;
}

.row-list span {
  color: #697077;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.card-template {
  border-radius: 8px;
  background: #F7F7F7;
  margin-top: 15px;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
}

.row-templates {
  margin: 0px;
  padding: 0px;
  border-radius: 8px;
  background: #F7F7F7;
  width: 100%;
  margin-top: 10px;
}
.c-pointer {
  cursor: pointer !important;
}
::v-deep #sidebar-right-group {
  background-color: var(--background-2) !important;
  width: 525px;

  small {
    color: var(--gray-font-color);
  }

  // Default sidebar header
  .b-sidebar-header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    button.close {
      margin-left: auto;
      margin-right: unset;
    }
  }

  // Custom sidebar header
  .sidebar-header {
    display: flex;
    align-items: center;
    padding-top: 2.5rem;

    p {
      font-weight: 500;
      font-size: 1.25rem;
    }

    span {
      color: var(--gray-font-color-5);
    }

    span.sidebar-close {
      position: absolute;
      top: 12px;
      right: 0;
    }

    .arrow-back {
      position: absolute;
      top: 12px;
      left: -10px;
    }
  }

  .b-sidebar-body {
    padding: 0 1.5rem;
  }

  header {
    >strong {
      order: -1;
    }
  }

  .desc-column {
    p {
      color: var(--gray-font-color-4);
    }
  }
}

::v-deep #sidebar-right-contact {
  background-color: var(--background-2) !important;
  width: 625px;

  small {
    color: var(--gray-font-color);
  }

  // Default sidebar header
  .b-sidebar-header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    button.close {
      margin-left: auto;
      margin-right: unset;
    }
  }

  // Custom sidebar header
  .sidebar-header {
    display: flex;
    align-items: center;
    padding-top: 2.5rem;

    p {
      font-weight: 500;
      font-size: 1.25rem;
    }

    span {
      color: var(--gray-font-color-5);
    }

    span.sidebar-close {
      position: absolute;
      top: 12px;
      right: 0;
    }

    .arrow-back {
      position: absolute;
      top: 12px;
      left: -10px;
    }
  }

  .b-sidebar-body {
    padding: 0 1.5rem;
  }

  header {
    >strong {
      order: -1;
    }
  }

  .desc-column {
    p {
      color: var(--gray-font-color-4);
    }
  }
}
.header-options {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 22px;
}

.header-options-title {
  width: 30%;
}

.header-options-title span {
  color: #0D0519;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.header-options-buttons {
  display: flex;
}

.header-options-buttons span {
  color: #6B23DC;
  display: inline-block;
  width: 32px;
  height: 32px;
  padding: 4px;
  background: rgba(107, 35, 220, 0.08);
  border-radius: 8px;
  margin-right: 16px;
}

.header-options-buttons h4 {
  color: #6B23DC;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-top: 5px;
}
.custom-control.custom-checkbox {
  margin-top: -15px;
  padding-left: 15px;
}
.fallback-image {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: #fff;
}

.profile-photo {
  width: 32px;
  height: 32px;
  margin-right: 15px;

  figure {
    border-radius: 48px;
    background: #752de6;
    width: 32px;
    height: 32px;
    margin: 0px;
  }

  &:hover {
    color: #fc0;

    &~.profile-more-details {
      opacity: 1;
      z-index: 100;
    }
  }
}
.contact-name {
  margin-top: 3px;
}

.contact-phone {
  margin-top: 5px;
  display: block;
  color: #b7b7b7 !important;
  font-size: 12px !important;
}

.contact-image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
}
::v-deep #sidebar-right-import {
  background-color: var(--background-2) !important;
  width: 525px;

  small {
    color: var(--gray-font-color);
  }

  // Default sidebar header
  .b-sidebar-header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    button.close {
      margin-left: auto;
      margin-right: unset;
    }
  }

  // Custom sidebar header
  .sidebar-header {
    display: flex;
    align-items: center;
    padding-top: 2.5rem;

    p {
      font-weight: 500;
      font-size: 1.25rem;
    }

    span {
      color: var(--gray-font-color-5);
    }

    span.sidebar-close {
      position: absolute;
      top: 12px;
      right: 0;
    }

    .arrow-back {
      position: absolute;
      top: 12px;
      left: -10px;
    }
  }

  .b-sidebar-body {
    padding: 0 1.5rem;
  }

  header {
    >strong {
      order: -1;
    }
  }

  .desc-column {
    p {
      color: var(--gray-font-color-4);
    }
  }
}

.drop-item-upload {
  width: 100%;
  height: 350px;
  border: dashed 2px #697077;
  border-radius: 8px;
  margin-top: 18px;
  text-align: center;
}

.drop-item-upload h3 {
  color: #595959;
  font-size: 16px;
  font-weight: 400;
  margin-top: 15px;
}

.drop-item-upload button {
  border: 1px solid #6B23DC;
  background: #fff;
  padding: 6px 24px 6px 24px;
  border-radius: 8px;
  color: #6B23DC;
  margin-top: 15px;
  font-size: 14px;
}

.drop-item-upload img {
  margin-top: 60px;
}

.drop-item-upload-p {
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
  color: #595959;
}
.csv-file {
  width: 100%;
  padding: 10px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #E5E5E5;
  margin: 0;
  margin-top: 15px;
}

.name-csv-div {
  width: 75%;
}

.name-csv-div span {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #595959;
}

.csv-progress {
  width: 100%;
  height: 8px;
  background: #6B23DC;
  border-radius: 6px;
}

.name-csv-percent {
  color: #000;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-top: 10px;
  margin-left: 10px;
}
@keyframes circle-chart-fill {
  to {
    stroke-dasharray: 0 100;
  }
}

@keyframes circle-chart-appear {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.circle-chart__circle {
  animation: circle-chart-fill 2s reverse;
  /* 1 */
  transform-origin: center;
  /* 4 */
}

.circle-chart__circle--negative {
  transform: rotate(-90deg) scale(1, -1);
  /* 1, 2, 3 */
}

.circle-chart__info {
  animation: circle-chart-appear 2s forwards;
  opacity: 0;
  transform: translateY(0.3em);
}

svg g text.circle-chart__percent {
  fill: #343D47;
  font-weight: 700;
}

svg g text.circle-chart__subline {
  fill: #C2C2C2;
  margin-top: 7px;
}

#svg circle {
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 1s linear;
  stroke: #cecece;
  stroke-width: 5px;
}

#svg #bar {
  stroke: #6B23DC;
}

#cont {
  display: block;
  height: 200px;
  width: 200px;
  margin: 0 auto;
  border-radius: 100%;
  position: relative;
  margin-top: 35px;
}

#cont:after {
  position: absolute;
  display: block;
  height: 160px;
  width: 160px;
  left: 50%;
  top: 50%;
  content: attr(data-pct)"%";
  margin-top: -80px;
  margin-left: -80px;
  border-radius: 100%;
  line-height: 160px;
  font-size: 2em;
}
.emoji-subject {
  position: absolute;
  right: 10px;
  top: 8px;
}
.no-template {
  display: block;
  font-size: 30px;
  margin-bottom: 10px;
  color: #ccc;
}
.input-domain-name {
  border-right: 2px solid #E5E5E5 !important;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  background: #f5f5f5;
  color: #a7a7a7;
  font-weight: 100; //
}
.content-upload {
  padding-top: 5px;
  min-height: 100px;
  width: 100%;
  border: dashed 2px #6F6F6F;
  border-radius: 8px;
  text-align: center;
  align-content: center;
}
.content-upload:hover {
  border: dashed 2px #ccc;
}
.content-upload span {
  color: #5A99EC;
  font-size: 50px;
}
.content-upload h4 {
  font-size: 14px;
  font-weight: 400;
  color: #595959;
}
.content-upload {
  cursor: pointer;
}
.content-upload input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}
.file-unic {
  float: none !important;
  margin: 0 auto !important;
}
.content-inside {
  padding: 10px;
}
.content-upload span {
  font-size: 30px;
}
.file-unic {
  margin-top: 0px !important;
}
.drop-img {
  height: 60px !important;
}
.file-unic {
  margin-top: 10% !important;
  float: left !important;
  margin: 5px !important;
  width: 40% !important;
  margin-bottom: 5px !important;
}
.modal-loader {
  position: fixed !important;
  width: 100%;
  height: 100%;
  padding-top: 60px;
  z-index: 1000;
}
</style>
